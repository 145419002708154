import FileUpload from "../../components/FileUploadExtra.js";
import React, { useState, useCallback } from "react";
import { CircularProgress, Button, Typography, Grid } from "@mui/material";
import { styled } from "@mui/system";
import processclaim from "../../assets/processclaim.png";
import axios from "axios";
import { Snackbar, Alert } from "@mui/material";
import useNetworkStatus from "../../components/ErrorPages/UseNetworkStatus.js";
import { useNavigate } from "react-router-dom";
import PreviewError from "../../components/ErrorPages/PreviewError.js";
import WordPreviewImage from "../../assets/word_issue.png";
import {
  useTheme,
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Paper,
  Box,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import jsPDF from "jspdf";


const StyledButton = styled(Button)(({ theme, marginTop }) => ({
  justifyContent: "center",
  textAlign: "center",
  width: "100%",
  maxWidth: 200,
  background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
  border: 0,
  borderRadius: 20,
  boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
  color: "white",
  height: 40,
  padding: "0 2px",
  margin: `2rem auto ${marginTop || "0"}`,
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  fontSize: "0.875rem",
  "&:hover": {
    background: "linear-gradient(45deg, #1976D2 30%, #00BCD4 90%)",
    transform: "scale(1.05)",
    boxShadow: "0 6px 10px 4px rgba(33, 203, 243, .4)",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
    fontSize: "0.75rem",
  },
}));

const SummaryFun = () => {
  const theme = useTheme();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previews, setPreviews] = useState([]);
  const [uploadIn] = useState("portal");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [filePreview, setFilePreview] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [extractedSummary, setExtractedSummary] = useState("");
  const [afterProcess, setAfterProcess] = useState(false);
  console.log("selectedFile::", selectedFile);
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_URL,
    withCredentials: true,
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const snackbarTimeoutRef = React.useRef(null); // Ref to manage the timeout
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleReset = () => {
    // Reset all relevant state variables
    setExtractedSummary(null); // Add this to reset the summary view
    setSelectedFile(null);
    setIsSubmitDisabled(true);
    setPreviews([]);
    setFilePreview(null);
    setLoading(false); // Reset loading state if applicable
  };
  const handleFilesUploadToSummary = (selectedFiles, previews) => {
    console.log("Selected files:", selectedFiles);
    setSelectedFile(selectedFiles[0]);
    setPreviews(previews);
    setIsSubmitDisabled(false);
  };
  const handleFileRemove = () => {
    handleReset();
  };
  const ExtractedDocumentComponent = () => {
    const { setNetworkError, SnackbarComponent } = useNetworkStatus();
    const [loader, setLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
  };
  const handleNetworkError = useCallback(
    (path) => {
      navigate(path);
    },
    [navigate]
  );

  const { setNetworkError, SnackbarComponent } = useNetworkStatus(
    {},
    handleNetworkError
  );

  const processSummary = async () => {
    if (!selectedFile) {
      alert("Please select a file first.");
      return;
    }
    console.log("Processing file:");
    console.log("File name:", selectedFile.name);
    console.log("File type:", selectedFile.type);

    setLoading(true);

    // Start a timeout to alert the user if processing takes too long
    const timeoutRef = setTimeout(() => {
      //   alert("Processing is taking longer than expected...");
    }, 5000);

    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      console.log("filename:::", selectedFile);

      const allowedFormats = [".pdf", ".doc", ".docx", "plain"];
      const allowedMimeTypes = [
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
        "text/plain",
      ];

      // Extract file extension and MIME type
      const fileExtension =
        "." + selectedFile.name.split(".").pop().toLowerCase();
      const fileType = selectedFile.type || ""; // Fallback in case type is empty

      // Validate file extension and MIME type
      if (
        !allowedFormats.includes(fileExtension) ||
        (!fileType && !allowedFormats.includes(fileExtension)) || // Allow empty type if extension matches
        (!allowedMimeTypes.includes(fileType) && fileType)
      ) {
        alert(
          "Invalid file format. Please upload a PDF, DOC, DOCX, or TXT file."
        );
        setLoading(false);
        return;
      }
      snackbarTimeoutRef.current = setTimeout(() => {
        setSnackbarOpen(true); // Show snackbar if request takes more than 5 seconds
      }, 1000);

      // Make the API call
      const response = await axiosInstance.post("AI/docai_summary/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Response from server 111:", response);
      // Clear timeout if the response arrives earlier than 5 seconds
      clearTimeout(snackbarTimeoutRef.current);
      setSnackbarOpen(false); // Ensure snackbar is closed

      if (response && response.data) {
        console.log("Response from server:", response.data);
        setFilePreview(URL.createObjectURL(selectedFile));
        setAfterProcess(true);

        // Handle the extracted summary
        if (response.data.Extracted_summary) {
          setExtractedSummary(response.data.Extracted_summary);
          const extractedSummary = response.data.Extracted_summary;
          //   alert("File processed successfully!");
          console.log("Extracted Summary:", extractedSummary);
          // You can update the UI with the extracted summary here
          // Example: setSummary(extractedSummary);
        } else {
          alert(
            "No summary could be extracted from the provided file. Please try again."
          );
          setFilePreview(URL.createObjectURL(selectedFile));
        }
      } else {
        alert("Unexpected response from the server. Please try again later.");
      }
    } catch (error) {
      console.error("Error while processing summary:", error);

      clearTimeout(snackbarTimeoutRef.current);
      setSnackbarOpen(false); // Ensure snackbar is closed

      if (error.response) {
        const { status } = error.response; // Capture the status code
        const errorMessage =
          error.response.data.message ||
          "A server error occurred. Please try again later.";
        const errorSource = error.response.data.api || "Unknown source";
        const userName = localStorage.getItem("userName");
        // Get the file object from formData
        const fileName = selectedFile ? selectedFile.name : "No file uploaded"; // Extract the file name
        const fileType = selectedFile ? selectedFile.type : "Unknown type"; // Extract the file type
        console.log("filetype: ", fileType);
        console.log("filename: ", fileName);
        console.log("Error Message--: ", errorMessage);
        console.log("username :", userName);
        console.log("status_code : ", status);
        console.log("errorSource --:-- ", errorSource);
        // Send both the error message, error source, and status to your backend
        setNetworkError({
          errorMessage: errorMessage,
          errorSource: errorSource, // Specify where the error occurred
          username: userName,
          fileName: fileName, // Include the file name
          fileType: fileType, // Include the file type
          status: status, // Include the status code
        });
      } else {
        alert(
          "A network or unexpected error occurred. Please check your connection and try again."
        );
      }
    } finally {
      setLoading(false);
      setSnackbarOpen(false);
    }
  };
  // const handleDocxConversion = async () => {
  //   if (
  //     !selectedFile ||
  //     selectedFile.type !==
  //       "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  //   ) {
  //     return;
  //   }

  //   try {
  //     const formData = new FormData();
  //     formData.append("file", selectedFile);

  //     const response = await axiosInstance.post(
  //       "AI/convert_docx_to_pdf/",
  //       formData,
  //       {
  //         responseType: "blob",
  //       }
  //     );

  //     const pdfBlob = new Blob([response.data], { type: "application/pdf" });
  //     const pdfUrl = URL.createObjectURL(pdfBlob);
  //     setFilePreview(pdfUrl);
  //   } catch (error) {
  //     console.error("PDF conversion error:", error);
  //   }
  // };
  // const processAndConvert = async () => {
  //   await processSummary(); // Process and extract data first
  //   await handleDocxConversion(); // Convert to PDF if applicable
  // };

  const downloadExtractedData = () => {
    const doc = new jsPDF({ orientation: "p", unit: "mm", format: "a4" });
  
    // Page dimensions
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
  
    // Retrieve user name from localStorage
    const userDetails = JSON.parse(localStorage.getItem("signinUserDetails")) || {};
    const userName = userDetails.user_name || "User";
  
    // Add main heading
    doc.setFontSize(18);
    doc.setFont(undefined, "bold");
    const mainHeadingText = "DocAI™ Summary";
    const mainHeadingWidth = doc.getTextDimensions(mainHeadingText).w;
    const mainHeadingX = (pageWidth - mainHeadingWidth) / 2;
    doc.text(mainHeadingText, mainHeadingX, 15);
  
    // Add user name and date
    doc.setFontSize(10);
    doc.setFont(undefined, "normal");
    const currentDate = new Date().toLocaleDateString();
    doc.text(`User_Name: ${userName}`, 15, 25);
    doc.text(currentDate, pageWidth - 40, 25);
  
    // Set font for content
    doc.setFontSize(12);
    let yPosition = 35; // Starting Y position after user details
  
    // Process the extractedSummary, preserving its structure
    const sections = extractedSummary
      .split("**")
      .filter((section) => section.trim() !== "");
  
    sections.forEach((section, sectionIndex) => {
      // Alternate between headings and content
      if (sectionIndex % 2 === 1) {
        // Heading
        doc.setFontSize(14);
        doc.setFont(undefined, "bold");
  
        // Create background for headings
        const rectX = 10;
        const rectWidth = pageWidth - 20;
        const rectHeight = 10; // Standard height for rectangle
        const rectY = yPosition - rectHeight / 2 + 5; // Adjusted Y for better centering
  
        doc.setFillColor(0, 51, 102); // Darker blue
        doc.rect(rectX, rectY, rectWidth, rectHeight, "F");
  
        // Center heading text vertically and horizontally
        doc.setTextColor(255, 255, 255); // White text
        const textWidth = doc.getTextDimensions(section.trim()).w;
        const textX = rectX + (rectWidth - textWidth) / 2;
  
        // Calculate text baseline offset for perfect centering
        const textHeight = doc.getTextDimensions(section.trim()).h;
        const textY = rectY + rectHeight / 2 + textHeight / 3.5; // Adjust for font baseline alignment
        doc.text(section.trim(), textX, textY);
  
        yPosition += rectHeight + 10; // Add space after heading
      } else {
        // Content
        doc.setFontSize(12);
        doc.setFont(undefined, "normal");
        doc.setTextColor(0, 0, 0); // Black text
  
        // Split content into lines
        const lines = section.split("*").filter((line) => line.trim() !== "");
        lines.forEach((line) => {
          // Wrap text if too long
          const splitLines = doc.splitTextToSize(line.trim(), pageWidth - 30);
          doc.text(splitLines, 15, yPosition);
          yPosition += 10 * splitLines.length; // Adjust for wrapped lines
        });
      }
  
      // Add some space between sections
      yPosition += 5;
  
      // Check for page break
      if (yPosition > pageHeight - 20) {
        doc.addPage();
        yPosition = 15; // Reset Y position for new page
      }
    });
  
    doc.save("DocAI-Summary.pdf");
  };
  
  

  return (
    <>
      <div>
        {/* Other component code */}
        {SnackbarComponent()} {/* Render the Snackbar from the hook */}
      </div>
      <Box sx={{ width: "100%", maxWidth: 1200, margin: "auto" }}>
        {!extractedSummary ? (
          <>
           <Typography
                className="Nasaliza"
                variant="h4"
                sx={{
                  color: "#000166",
                  fontWeight: 400,
                  letterSpacing: "0.5px",
                  lineHeight: 1.2,
                  mb: 2,
                  fontSize: { xs: "1.5rem", sm: "2rem", md: "1.7rem" }, // Responsive font size
                  textAlign: "Center",
                }}
              >
               Upload Document
              </Typography>
            <FileUpload
              multiple={false}
              allowedFormats={[
                "pdf",
                "plain",
                "txt",
                "vnd.openxmlformats-officedocument.wordprocessingml.document",
              ]}
              setIsSubmitDisabled={setIsSubmitDisabled}
              selectedFilesInParent={selectedFile ? [selectedFile] : []}
              filePreviews={previews}
              uploadIn={uploadIn}
              onFilesUpload={handleFilesUploadToSummary}
              onFileRemove={handleFileRemove}
            />
            <Grid sx={{ marginTop: "1rem" }}>
              <StyledButton
                onClick={processSummary}
                disabled={isSubmitDisabled || loading}
                startIcon={
                  loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <img
                      src={processclaim}
                      alt="process and claim icon"
                      style={{ height: 24 }}
                    />
                  )
                }
              >
                {loading ? "Processing..." : "Process file"}
              </StyledButton>
            </Grid>
          </>
        ) : (
          <Grid container spacing={2} sx={{ height: "100%" }}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Typography variant="h6">Document Preview</Typography>
              {filePreview ? (
                <div style={{ marginTop: isMobile ? "50px" : "100px" }}>
                  {selectedFile?.type === "application/pdf" ? (
                    <iframe
                      src={filePreview}
                      width="100%"
                      height={isMobile ? "500" : "700"}
                      title="File Preview"
                      style={{
                        marginTop: isMobile ? "-50px" : "-100px",
                        border: "2px solid black",
                      }}
                    />
                  ) : selectedFile?.type ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                    <div
                      style={{
                        marginTop: isMobile ? "-50px" : "-100px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        border: "2px solid black",
                        padding: "20px",
                      }}
                    >
                     <img
  src={WordPreviewImage}
  alt="Word Document Preview"
  style={{
    width: "100%",
    height: "auto",
    marginTop:'80px',
    marginBottom: "40px",
  }}
/>
                      <a
                        href={filePreview}
                        download={selectedFile.name}
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#007bff",
                          color: "white",
                          textDecoration: "none",
                          borderRadius: "5px",
                          fontWeight: "bold",
                          transition: "background-color 0.3s ease",
                          marginTop: "185px",
                        }}
                        onMouseOver={(e) =>
                          (e.target.style.backgroundColor = "#0056b3")
                        }
                        onMouseOut={(e) =>
                          (e.target.style.backgroundColor = "#007bff")
                        }
                      >
                        Download Document
                      </a>
                    </div>
                  ) : selectedFile?.type === "text/plain" ? (
                    <div
                      style={{
                        marginTop: isMobile ? "-50px" : "-100px",
                        padding: "10px",
                        border: "2px solid black",
                        borderRadius: "5px",
                        overflowY: "auto",
                        maxHeight: isMobile ? "500px" : "700px",
                      }}
                    >
                      {extractedSummary}
                    </div>
                  ) : (
                    <img
                      src={filePreview}
                      alt="File Preview"
                      style={{
                        width: "100%",
                        height: "auto",
                        marginTop: isMobile ? "-45px" : "-90px",
                      }}
                    />
                  )}
                </div>
              ) : (
                <PreviewError />
              )}
              <StyledButton
                onClick={handleReset}
                marginTop={isMobile ? "150px" : "300px"}
              >
                Reupload
              </StyledButton>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 0,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    // display: "flex",
                    // alignItems: "center",
                    marginBottom: "-32px",
                  }}
                >
                  Extracted Summary
                  <DownloadIcon
                    sx={{
                      // marginLeft: 1, // Add spacing between text and icon
                      cursor: "pointer",
                      color: "#3a7bd5",
                      "&:hover": { color: "#0056b3" },
                      fontSize: "1.5rem",
                      marginLeft: "550px",
                      marginTop: "-60px",
                    }}
                    onClick={downloadExtractedData}
                    titleAccess="Download Extracted Summary"
                  />
                </Typography>
              </Box>

              <Box
                className="summary-box"
                sx={{
                  border: "1px solid rgba(0,0,0,0.12)",
                  borderRadius: 3,
                  padding: 3,
                  maxHeight: "705px",
                  overflowY: "auto",
                  background:
                    "linear-gradient(145deg, #f0f4f8 0%, #e6eaf0 100%)",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  flexGrow: 1,
                }}
              >
                <div
                  id="summary-content"
                  style={{
                    userSelect: "text",
                    WebkitUserSelect: "text", // Note the capital 'W' for vendor prefix
                  }}
                >
                  {extractedSummary.split("**").map((section, sectionIndex) => {
                    // Skip empty sections
                    if (section.trim() === "") return null;

                    // Determine if this is a heading or content
                    const isHeading = sectionIndex % 2 === 1;

                    if (isHeading) {
                      // Styling for section headings
                      return (
                        <Box
                          key={sectionIndex}
                          sx={{
                            marginTop: 2,
                            marginBottom: 2,
                          }}
                        >
                          <Typography
                            variant="h6"
                            className="Nasaliza"
                            sx={{
                              textAlign:
                                section.trim() === "Document Context"
                                  ? "center"
                                  : "left",
                              background:
                                section.trim() === "Document Context"
                                  ? "linear-gradient(to right, #6a11cb 0%, #2575fc 100%)"
                                  : "linear-gradient(to right, #3a7bd5 0%, #00d2ff 100%)",
                              color: "white",
                              padding: 1,
                              borderRadius: 2,
                              fontWeight: 100,
                              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                            }}
                          >
                            {section.trim()}
                          </Typography>
                        </Box>
                      );
                    }

                    // Content processing
                    return section.split("*").map((line, lineIndex) => {
                      // Remove leading and trailing whitespace
                      line = line.trim();

                      // Skip empty lines
                      if (line === "") return null;

                      // Array of vibrant colors for gradient effects
                      const colors = [
                        "linear-gradient(to right, #6a11cb 0%, #2575fc 100%)",
                        "linear-gradient(to right, #ff6a00 0%, #ee0979 100%)",
                        "linear-gradient(to right, #00b09b 0%, #96c93d 100%)",
                        "linear-gradient(to right, #8e2de2 0%, #4a00e0 100%)",
                        "linear-gradient(to right, #ff5f6d 0%, #ffc371 100%)",
                      ];

                      // Cycle through colors for each point
                      const backgroundGradient =
                        colors[lineIndex % colors.length];

                      return (
                        <Box
                          key={lineIndex}
                          sx={{
                            display: "flex",
                            alignItems: "flex-start", // Align items to the start
                            marginBottom: 2,
                            background: "rgba(255,255,255,0.7)",
                            borderRadius: 2,
                            padding: 1,
                            boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
                          }}
                        >
                          <Box
                            sx={{
                              width: 14, // Bullet size
                              height: 14, // Bullet size
                              borderRadius: "50%",
                              background: backgroundGradient,
                              marginRight: 2, // Adjust spacing to the left of the sentence
                              marginTop: "7px",
                              flexShrink: 0,
                              boxShadow: "0 3px 6px rgba(0,0,0,0.16)",
                            }}
                          />
                          <Typography
                            variant="body1"
                            sx={{
                              flex: 1,
                              color: "#333",
                              fontWeight: 500,
                              lineHeight: 1.6,
                              textAlign: "left",
                            }}
                          >
                            {line}
                          </Typography>
                        </Box>
                      );
                    });
                  })}
                </div>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: "botom", horizontal: "right" }} // Top Center Position
        autoHideDuration={15000} // Automatically hide after 6 seconds if not closed earlier
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="info"
          sx={{ width: "100%" }}
        >
          The document is under process. Please wait.
        </Alert>
      </Snackbar>
    </>
  );
};
export default SummaryFun;
