import React, { useState, useCallback, useRef } from "react";
import {
  Grid,
  Box,
  Paper,
  Typography,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  Alert,
  Snackbar,
} from "@mui/material";
import FileUpload from "../../components/FileUploadExtra.js";
import StyledButtonComponent from "../../components/StyledButton";
import processclaim from "../../assets/processclaim.png";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import useNetworkStatus from "../../components/ErrorPages/UseNetworkStatus.js";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DescriptionIcon from "@mui/icons-material/Description";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import GetAppIcon from "@mui/icons-material/GetApp";
import { BarChart, XAxis, YAxis, Bar } from "recharts"; // Import from recharts

const CustomActiveShapePieChart = ({
  successDocuments,
  workQueueDocuments,
  anonymousWorkQueueDocuments,
  uploadedDocuments,
  totalProcessedDocuments,
}) => {
  console.log(
    successDocuments,
    workQueueDocuments,
    uploadedDocuments,
    totalProcessedDocuments
  );

  const successPercentage = totalProcessedDocuments
    ? (successDocuments / totalProcessedDocuments) * 100
    : 0;
  const workQueuePercentage = totalProcessedDocuments
    ? (workQueueDocuments / totalProcessedDocuments) * 100
    : 0;
  const anonymousWorkQueuePercentage = totalProcessedDocuments
    ? (anonymousWorkQueueDocuments / totalProcessedDocuments) * 100
    : 0;

  const data =
    totalProcessedDocuments > 0
      ? [
          {
            name: "Success",
            percentage: successPercentage,
            count: successDocuments,
          },
          {
            name: "WorkQueue",
            percentage: workQueuePercentage,
            count: workQueueDocuments,
          },
          {
            name: "Anonymous WorkQueue",
            percentage: anonymousWorkQueuePercentage,
            count: anonymousWorkQueueDocuments,
          },
        ]
      : [{ name: "Not Uploaded Documents", percentage: 100, count: 0 }];

  const COLORS =
    totalProcessedDocuments > 0
      ? ["#4CAF50", "#ffc107", "#F44336"]
      : ["#2196F3"];

  // State to track hovered bar
  const [hoveredBar, setHoveredBar] = useState(null);

  const handleMouseEnter = (data, index) => {
    setHoveredBar(data);
  };

  const handleMouseLeave = () => {
    setHoveredBar(null);
  };

  return (
    <Box>
      <Typography
        variant="h6"
        style={{
          textAlign: "center",
          marginBottom: 16,
          color: "#001660",
          fontSize: "1.3rem",
        }}
        className="Nasaliza"
      >
        DocAI Classify Status Distribution
      </Typography>

      <Grid container justifyContent="center">
        <Grid item>
          <Box display="flex" flexDirection="row" alignItems="center">
            <ResponsiveContainer width={450} height={300}>
              <BarChart
                data={data.map((item) =>
                  item.name === "Anonymous WorkQueue"
                    ? { ...item, name: "Anonymous" }
                    : item
                )}
                margin={{ top: 20, right: 30, bottom: 40, left: 20 }}
              >
                <XAxis
                  dataKey="name"
                  stroke="#001660"
                  tick={{ fontSize: 12, fill: "#001660" }}
                  interval={0}
                  angle={-45}
                  textAnchor="end"
                />
                <YAxis
                  stroke="#001660"
                  tick={{ fontSize: 12, fill: "#001660" }}
                  width={50}
                  domain={[0, 100]} // Set Y-Axis to always range from 0 to 100
                />
                <Bar
                  dataKey="percentage"
                  radius={[4, 4, 0, 0]}
                  barSize={20}
                  onMouseEnter={(e) => handleMouseEnter(e, e.index)}
                  onMouseLeave={handleMouseLeave}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                      stroke="none"
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>

            {/* Labels to the right of the bar chart */}
            <Box ml={4}>
              <Typography
                style={{
                  fontSize: "0.8rem",
                  textAlign: "left",
                  marginBottom: "8px",
                  marginTop: "-120px",
                }}
                color="#010066"
                fontWeight="bold"
              >
                Uploaded : {uploadedDocuments}
              </Typography>
              <Typography
                style={{
                  fontSize: "0.8rem",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
                color="green"
                fontWeight="bold"
              >
                Success : {successDocuments}
              </Typography>
              <Typography
                style={{
                  fontSize: "0.8rem",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
                color="#ffc107"
                fontWeight="bold"
              >
                WorkQueue : {workQueueDocuments}
              </Typography>
              <Typography
                style={{
                  fontSize: "0.8rem",
                  textAlign: "left",
                  marginBottom: "8px",
                }}
                color="#f44336"
                fontWeight="bold"
              >
                Anonymous WorkQueue : {anonymousWorkQueueDocuments}
              </Typography>
              <Typography
                style={{ fontSize: "0.8rem", textAlign: "left" }}
                color="Blue"
                fontWeight="bold"
              >
                Total Processed : {totalProcessedDocuments}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Hover Effect Display */}
      {hoveredBar && (
        <Box
          style={{
            marginTop: "10px",
            fontSize: "0.9rem",
            fontWeight: "bold",
            color: "#010066",
            display: "flex",
            justifyContent: "center",
            gap: "15px",
          }}
        >
          <Typography>{`${hoveredBar.name}: ${hoveredBar.percentage.toFixed(
            2
          )}%`}</Typography>
          <Typography>{`(${hoveredBar.count} docs)`}</Typography>
        </Box>
      )}
    </Box>
  );
};

const DocAIClassifyFullFun = () => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false); // For success message
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeviarity, setSnackbarSeviarity] = useState("success");
  const [loading, setLoading] = useState(false);
  const [uploadedCount, setUploadedCount] = useState(0); // New state for tracking uploaded count
  const [processedData, setProcessedData] = useState(null);
  const [open, setOpen] = useState(false); // Document dialog state
  const [selectedFileContent, setSelectedFileContent] = useState(null); // For content
  const [documentName, setDocumentName] = useState(""); // Name of the document
  const [contentType, setContentType] = useState(null); // Content type (pdf, html)
  const fileUploadRef = useRef(null); // For the FileUpload component    const fileUploadRef = useRef(null);
  const [docAIStats, setDocAIStats] = useState({
    // Use state for chart data
    successDocuments: 0,
    workQueueDocuments: 0,
    totalProcessedDocuments: 0,
    anonymousWorkQueueDocuments: 0,
  });
  const navigate = useNavigate();
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_URL,
    withCredentials: true,
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleFilesUpload = (selectedFiles, previews) => {
    setUploadedDocuments(selectedFiles);
    setUploadedCount(selectedFiles.length); // Set the uploaded count
    setIsSubmitDisabled(false);
    setProcessedData(null); // Clear previous data
    setDocAIStats({
      successDocuments: 0,
      workQueueDocuments: 0,
      totalProcessedDocuments: 0,
      anonymousWorkQueueDocuments: 0,
    });
  };
  const handleFileRemove = (fileToRemove) => {
    const updatedFiles = uploadedDocuments.filter(
      (file) => file !== fileToRemove
    );
    setUploadedDocuments(updatedFiles);
    setUploadedCount(updatedFiles.length); // Update the uploaded count
    if (updatedFiles.length === 0) {
      setIsSubmitDisabled(true);
    }
  };
  const handleNetworkError = useCallback(
    (path) => {
      navigate(path);
    },
    [navigate]
  );
  const { setNetworkError } = useNetworkStatus({}, handleNetworkError);
  const processDocuments = async () => {
    if (!uploadedDocuments || uploadedDocuments.length === 0) {
      alert("Please upload the documents first.");
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();
      for (const file of uploadedDocuments) {
        formData.append("files", file);
      }

      const response = await axiosInstance.post(
        "AI/process_docaiclassify_files/",
        formData
      );

      if (response.data) {
        setProcessedData(response.data);
        setDocAIStats({
          successDocuments: response.data.success_records.length,
          workQueueDocuments: response.data.workqueue_records.length,
          anonymousWorkQueueDocuments:
            response.data.anonymous_workqueue_records.length,
          totalProcessedDocuments:
            response.data.success_records.length +
            response.data.workqueue_records.length +
            response.data.anonymous_workqueue_records.length,
        });
        setSnackbarMessage("Documents processed successfully!");
        setSnackbarSeviarity("success");
        setShowSnackbar(true);
      } else {
        setSnackbarMessage("Error while processing documents");
        setSnackbarSeviarity("error");
        setShowSnackbar(true);
      }
    } catch (error) {
      console.error("error in processing documents", error);
      setSnackbarMessage("Error processing documents");
      setSnackbarSeviarity("error");
      setShowSnackbar(true);
    } finally {
      setLoading(false);
      setIsSubmitDisabled(true);
      // Clear uploaded files from state AND the UI
      setUploadedDocuments([]);
      if (fileUploadRef.current) {
        fileUploadRef.current.handleClearAllFiles();
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFileContent(null); // Clear content on close
    setDocumentName("");
    setContentType(null);
  };

  const KeyValueTable = ({ record, status }) => {
    const {
      file_name,
      file_classification_name,
      index_data,
      document_content,
      content_type,
    } = record;

    const formattedIndexData = Object.entries(index_data).map(
      ([key, value]) => ({
        key: key.replace(/_/g, " "),
        value,
      })
    );

    const statusIcon = (
      <Tooltip title={status}>
        {" "}
        {/* Tooltip for status icon */}
        {status === "Success" && (
          <CheckCircleOutlineIcon sx={{ color: "green" }} />
        )}
        {status === "WorkQueue" && (
          <WarningAmberIcon sx={{ color: "orange" }} />
        )}
        {status === "Anonymous WorkQueue" && (
          <ErrorOutlineIcon sx={{ color: "red" }} />
        )}
      </Tooltip>
    );

    const handleViewDocument = () => {
      setSelectedFileContent(document_content); // Store content directly
      setDocumentName(file_name);
      setContentType(content_type);
      setOpen(true);
    };

    return (
      <TableRow>
        <TableCell sx={{ maxWidth: "50px", verticalAlign: "middle" }}>
          {statusIcon} {/* Render the status icon with tooltip */}
        </TableCell>
        <TableCell
          sx={{
            fontSize: "0.8rem",
            wordBreak: "break-all",
            maxWidth: "150px",
            verticalAlign: "middle",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {file_name}
        </TableCell>
        <TableCell
          sx={{
            fontSize: "0.8rem",
            wordBreak: "break-all",
            maxWidth: "150px",
            verticalAlign: "middle",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {file_classification_name}
        </TableCell>
        {formattedIndexData.map(({ key, value }, index) => (
          <TableCell
            key={index}
            sx={{
              fontSize: value?.length > 15 ? "0.8rem" : "0.8rem",
              wordBreak: "break-word",
              verticalAlign: "middle",
              maxWidth: "150px",
            }}
          >
            {" "}
            {/* Adjusted styles */}
            <Tooltip title={key}>
              <span style={{ whiteSpace: "pre-wrap" }}>{value}</span>{" "}
              {/* Preserve whitespace */}
            </Tooltip>
          </TableCell>
        ))}
        {[...Array(7 - formattedIndexData.length)].map((_, index) => (
          <TableCell
            key={index + formattedIndexData.length}
            sx={{ verticalAlign: "middle" }}
          >
            {" "}
            <Tooltip title="Not Applicable"> {/* Tooltip for "-" */}-</Tooltip>
          </TableCell>
        ))}
        <TableCell sx={{ verticalAlign: "middle" }}>
          <Tooltip title="View Document">
            <IconButton onClick={handleViewDocument}>
              <DescriptionIcon sx={{ color: "#2196f3" }} />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box
            elevation={3}
            sx={{
              // width: "100%",
              // maxWidth: 1000,
              margin: "auto",
              height: isMobile ? "" : "430px",
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
              mt: 9,
              // borderRadius: 3,
              // p: 4,
              // backdropFilter: "blur(10px)", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",
              // border: "1px solid rgba(255, 255, 255, 0.2)",
              // background: 'transparent',
            }}
          >
            <Typography
              sx={{
                color: "#001660",
                fontSize: "1.3rem",
                marginBottom: "10px",
              }}
              className="Nasaliza"
            >
              Upload Documents
            </Typography>
            <FileUpload
              ref={fileUploadRef}
              id="portal"
              onFilesUpload={handleFilesUpload}
              multiple={true}
              allowedFormats={["pdf"]}
              setIsSubmitDisabled={setIsSubmitDisabled}
              onFileRemove={handleFileRemove}
            />
            <Box sx={{ textAlign: "center", marginTop: 2 }}>
              <StyledButtonComponent
                variant="contained"
                color="primary"
                onClick={processDocuments}
                buttonWidth={150}
                disabled={
                  isSubmitDisabled || uploadedDocuments.length === 0 || loading
                }
                startIcon={
                  loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <img
                      src={processclaim}
                      alt="process documents"
                      style={{ height: 24 }}
                    />
                  )
                }
              >
                {loading ? "Processing..." : "Process"}
              </StyledButtonComponent>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          {processedData && (
            <Paper
              elevation={3}
              sx={{
                // width: "100%",
                // maxWidth: 1000,
                // marginLeft: "300px",
                height: isMobile ? "" : "430px",
                marginTop: "-250px",
                borderRadius: 3,
                p: 3,
                backdropFilter: "blur(10px)",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",
                border: "1px solid rgba(255, 255, 255, 0.2)",
                background: "transparent",
              }}
            >
              <CustomActiveShapePieChart
                successDocuments={docAIStats.successDocuments} // Use values from state
                workQueueDocuments={docAIStats.workQueueDocuments}
                uploadedDocuments={uploadedCount}
                totalProcessedDocuments={docAIStats.totalProcessedDocuments}
                anonymousWorkQueueDocuments={
                  docAIStats.anonymousWorkQueueDocuments
                }
              />
            </Paper>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          {processedData && (
            <Paper
              elevation={3}
              sx={{ p: 3, borderRadius: 3, boxShadow: 3, background: "white" }}
            >
              {" "}
              {/* Improved background and box-shadow */}
              <Typography
                variant="h5"
                align="center"
                className="Nasaliza"
                gutterBottom
                sx={{ color: "#010066", fontWeight: "bold", mb: 3 }}
              >
                {" "}
                {/* Darker heading color, bold */}
                Processed Documents Result
              </Typography>
              <TableContainer
                component={Paper}
                sx={{
                  background: "transparent",
                  boxShadow: "none",
                  border: "none",
                  mb: 4,
                  maxWidth: "100%",
                  overflowX: "auto",
                }}
              >
                {" "}
                {/* Added overflowX */}
                <Table sx={{ tableLayout: "fixed", width: "100%" }}>
                  {" "}
                  {/* Set width to 100% */}
                  <TableHead sx={{ bgcolor: "#2196f3", color: "white" }}>
                    <TableRow>
                      <TableCell sx={{ color: "white", width: "5%" }}>
                        Status
                      </TableCell>{" "}
                      {/* Set width for Status */}
                      <TableCell sx={{ color: "white", width: "10%" }}>
                        File Name
                      </TableCell>{" "}
                      {/* Set width for File Name */}
                      <TableCell sx={{ color: "white", width: "10%" }}>
                        Classification
                      </TableCell>{" "}
                      {/* Set width for Classification Type */}
                      {[...Array(7)].map((_, index) => (
                        <TableCell
                          key={index}
                          sx={{ color: "white", width: "8%" }}
                        >
                          Index {index + 1}
                        </TableCell> // Set width for Index columns
                      ))}
                      <TableCell sx={{ color: "white", width: "5%" }}>
                        View
                      </TableCell>{" "}
                      {/* Set width for View */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {processedData.success_records?.length > 0 &&
                      processedData.success_records.map((record, index) => (
                        <KeyValueTable
                          key={index}
                          record={record}
                          status="Success"
                        />
                      ))}

                    {processedData.workqueue_records?.length > 0 &&
                      processedData.workqueue_records.map((record, index) => (
                        <KeyValueTable
                          key={index}
                          record={record}
                          status="WorkQueue"
                        />
                      ))}

                    {processedData.anonymous_workqueue_records?.length > 0 &&
                      processedData.anonymous_workqueue_records.map(
                        (record, index) => (
                          <KeyValueTable
                            key={index}
                            record={record}
                            status="Anonymous WorkQueue"
                          />
                        )
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </Grid>
      </Grid>

      {/* Document View Dialog */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>{documentName}</DialogTitle>
        <DialogContent>
          {contentType === "pdf" && (
            <iframe
              src={`data:application/pdf;base64,${selectedFileContent}`}
              title="Selected Document"
              width="100%"
              height="500px"
            />
          )}
          {contentType === "html" && (
            <div dangerouslySetInnerHTML={{ __html: selectedFileContent }} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} startIcon={<CloseIcon />}>
            Close
          </Button>

          <Button
            onClick={() => {
              const link = document.createElement("a");
              link.href = `data:application/pdf;base64,${selectedFileContent}`; // Or data:text/html,... for HTML
              link.download = documentName;
              link.click();
            }}
            startIcon={<GetAppIcon />}
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>
      {/* Snackbar for success and failure message */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }} // Position at top right
      >
        <Alert onClose={() => setShowSnackbar(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DocAIClassifyFullFun;
