import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Paper,
  Typography,
  Box,
  FormHelperText,
  Snackbar,
  Alert,
  Button,
  InputAdornment,
  CircularProgress,
  IconButton, Tooltip
} from '@mui/material';
import SelectField from '../../Fields/SelectField';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import StyledButtonComponent from '../../../components/StyledButton';
import { NavigateNext as NavigateNextIcon } from "@mui/icons-material";
import { states } from "../../../data/states.js";

import '../PolicyIntake/Policy.css';
import axios from 'axios';
import {
  Edit as EditIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from '@mui/icons-material';

const PolicyInfo = ({ onNext, formData, setFormData, confirmAddress, setConfirmAddress, editAddress, setEditAddress, enableReviewButton, onReviewClick, updateStepValidity,setValidateError,setCheckValidaionName,  isStepAddressValid }) => {
  const [errors, setErrors] = useState({});
  const [isAddressValid, setIsAddressValid] = useState(false); // State for validation button
  const [isValidatingAddress, setIsValidatingAddress] = useState(false); // Loading state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [suggestedAddress, setSuggestedAddress] = useState(null);
  const [spittedAddress, setSpittedAddress] = useState(null);
  const [showAddress, setShowAddress] = useState(false);

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_URL,
    withCredentials: true
  });

  useEffect(() => {
    console.log(confirmAddress);
    
    const isValid = isFormValid();
    updateStepValidity(isValid);
    // eslint-disable-next-line

  }, [formData, errors, confirmAddress]);


  const handlePolicyInfoChange = (event, date, fieldName) => {
    if (fieldName) {
      // Ensure date is not null or undefined before calling toISOString
      const formattedDate = date ? date.toISOString().split("T")[0] : ''; // Format date as YYYY-MM-DD or set as an empty string

      // Update the roof field in formData with the selected date
      setFormData((prevFormData) => ({
        ...prevFormData,
        policyInfo: {
          ...prevFormData.policyInfo,
          [fieldName]: formattedDate,
        },
      }));

      // Validate the field with the formatted date or empty string
      validateField(fieldName, formattedDate);
    } else if (event) {
      // Handle other input changes

      const { name, value } = event.target;

      // Format SSN as the user types
      if (name === 'policy_holder_ssn') {
        const formattedSSN = formatSSN(value);
        setFormData((prevFormData) => ({
          ...prevFormData,
          policyInfo: {
            ...prevFormData.policyInfo,
            [name]: formattedSSN,
          },
        }));
        validateField(name, formattedSSN); // Validate the formatted value
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          policyInfo: {
            ...prevFormData.policyInfo,
            [name]: value,
          },
        }));
        // Check if address fields have changed to enable/disable validation
        if (['policy_holder_street_number', 'policy_holder_street_name', 'policy_holder_zip'].includes(name)) {
          checkAddressValidity();
        }
        validateField(name, value);
      }
    }
    // After updating formData, check form validity
    const isValid = isFormValid();
    updateStepValidity(isValid);
  };

  // Function to check if address fields are filled
  const checkAddressValidity = () => {
    setIsAddressValid(
      formData.policy_holder_street_number &&
      formData.policy_holder_street_name &&
      formData.policy_holder_zip &&
      !errors.policy_holder_street_number && // Check for errors
      !errors.policy_holder_street_name &&
      !errors.policy_holder_zip
    );
  };

  console.log("is address valids", isAddressValid);


  // Function to handle address validation API call (update with your API logic)
  const handleValidateAddress = async () => {
    setIsValidatingAddress(true);
    try {
      const address = `${formData.policy_holder_street_number} ${formData.policy_holder_street_name}, ${formData.policy_holder_city}, ${formData.policy_holder_state} ${formData.policy_holder_zip} ${formData.policy_holder_country}`;
      const response = await axiosInstance.post('validate_address/', { address: address },);
      console.log('API Response:', response.data);

      if (response.data && response.data.validated_address && response.data.splitted_address) {
        setSuggestedAddress(response.data.validated_address);
        setSpittedAddress(response.data.splitted_address);
        setSnackbarSeverity('info');
        setShowAddress(true);
      }


    } catch (error) {
      console.error('Error during address validation:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage(
        error.response && error.response.data && error.response.data.error
          ? `${error.response.data.error}, Please check you address.`
          : 'An error occurred during address validation. Please try again later.'
      );
      setSnackbarOpen(true);

    } finally {
      setIsValidatingAddress(false);
    }
  };


  const handleConfirmAddress = (spittedAddress) => {
    console.log("clcikd for changing fileds", spittedAddress);
    setFormData((prevFormData) => ({
      ...prevFormData,
      policyInfo: {
        ...prevFormData.policyInfo,
        policy_holder_street_number: spittedAddress.street_number || '',
        policy_holder_street_name: spittedAddress.street_name || '',
        policy_holder_city: spittedAddress.city || '',
        policy_holder_state: spittedAddress.state || '',
        policy_holder_zip: spittedAddress.zip_code || '',
        policy_holder_country: spittedAddress.country || '',
      },
    }));

    // Clear the address field errors after confirming the address
    setErrors((prevErrors) => ({
      ...prevErrors,
      policy_holder_street_number: undefined,
      policy_holder_street_name: undefined,
      policy_holder_city: undefined,
      policy_holder_zip: undefined,
      policy_holder_country: undefined,
      policy_holder_state: undefined,
    }));

    setConfirmAddress(true);
    setEditAddress(false);
    setShowAddress(false)

  }

  const handleEditFields = () => {
    setConfirmAddress(false);
    setEditAddress(true);
  };


  useEffect(() => {
    checkAddressValidity();
    // eslint-disable-next-line
  }, [formData]); // Run once when the component mounts


  // Helper function to format SSN
  const formatSSN = (value) => {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{0,3})(\d{0,2})(\d{0,4})$/);
    if (match) {
      return !match[2]
        ? match[1]
        : `${match[1] ? match[1] + '-' : ''}${match[2]
        }${match[3] ? '-' + match[3] : ''}`;
    }
    return value; // Return as-is if not a valid format
  };

  const validateField = (name, value) => {
    const newErrors = { ...errors };
    console.log(name, value);
    switch (name) {
      case 'policy_holder_FirstName':
      case 'policy_holder_LastName':
        if (value.length > 20) {
          newErrors[
            name
          ] = 'Please enter a valid name (up to 20 characters, only letters and spaces).';
        } else if (!/^[A-Za-z\s]+$/.test(value)) {
          newErrors[name] = 'Please enter a valid name.';
        } else {
          delete newErrors[name];
        }
        break;
      case 'policy_holder_street_number':
        if (!/^\d+$/.test(value)) {
          newErrors[
            name
          ] = 'Please enter a valid street number (only integers).';
        } else {
          delete newErrors[name];
        }
        break;

      case 'policy_holder_street_name':
        if (value === '' || value === undefined) {
          newErrors[name] = 'Please enter a valid Street Name.';
        } else if (value.length > 40) {
          newErrors[name] = 'Street Name must be 40 characters or less.';
        } else {
          delete newErrors[name];
        }
        break;
      case 'policy_holder_city':
        if (!/^[A-Za-z\s]+$/.test(value)) {
          newErrors[
            name
          ] = 'Please enter a valid city name (only letters and spaces).';
        } else {
          delete newErrors[name];
        }
        break;

      case 'policy_holder_zip':
        if (!/^\d{5}$/.test(value)) {
          // Must be exactly 5 digits
          newErrors[name] = 'Please enter a valid 5-digit ZIP code.';
        } else {
          delete newErrors[name];
        }
        break;

      case 'policy_holder_mobile':
        if (!/^\d{10}$/.test(value)) {
          // Must be exactly 10 digits
          newErrors[name] = 'Please enter a valid 10-digit mobile number.';
        } else {
          delete newErrors[name];
        }
        break;

      case 'policy_holder_email':
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          newErrors[name] = 'Please enter a valid email address.';
        } else {
          delete newErrors[name];
        }
        break;

        case 'policy_holder_occupation':
          const occupationRegex = /^[a-zA-Z\s\-.']+$/;
        
          if (value === '' || value === undefined) {
            newErrors[name] = 'Please enter a valid occupation.';
          } else if (!occupationRegex.test(value)) {
            newErrors[name] = 'Occupation can only contain letters, spaces, hyphens, periods, and apostrophes.';
          } else if (value.length > 40) {
            newErrors[name] = 'Occupation must be 20 characters or less.';
          } else {
            delete newErrors[name];
          }
          break;
        
      case 'policy_holder_ssn':
        if (!/^\d{3}-?\d{2}-?\d{4}$/.test(value)) {
          newErrors[
            name
          ] = 'Please enter a valid SSN (9 digits, e.g., 999-99-9999).';
        } else {
          delete newErrors[name];
        }
        break;
      case 'selectedPolicy':
      case 'policy_holder_state':
      case 'policy_holder_country':
        if (value === '' || value === null) {
          newErrors[name] = `${name.replace('policy_holder_', '')} is required.`;
        } else {
          delete newErrors[name];
        }
        break;
      default:
        delete newErrors[name];
    }

    setErrors(newErrors);
  };



  const isFormValid = () => {
    const requiredFields = [
      'selectedPolicy',
      'policy_holder_FirstName',
      'policy_holder_LastName',
      'policy_holder_street_number',
      'policy_holder_street_name',
      'policy_holder_city',
      'policy_holder_state',
      'policy_holder_zip',
      'policy_holder_country',
      'policy_holder_mobile',
      'policy_holder_email',
      'policy_holder_occupation',
    ];

    for (const field of requiredFields) {
      if (formData[field] === '' || errors[field]) {
        return false;
      }
    }
    return true;
  };

  const handleNext = () => {
    if (isFormValid()) {
      setCheckValidaionName((prev) => prev.filter((name) => name !== 'Policy Holder Info'));
      onNext("policyInfo", formData); // Passing the correct section name
    } else {
      console.log("Form has errors or missing required fields. Please correct them.");
      setCheckValidaionName((prev) => [...prev, 'Policy Holder Info']); 
      setValidateError(true)
    }
  };
  const onReviewCheckValidation =()=>{
    if (isFormValid() && confirmAddress) {
      setCheckValidaionName((prev) => prev.filter((name) => name !== 'Policy Holder Info'));
      onReviewClick();
    } 
  }
  console.log(formData);


  const CustomStylesForTextFileds = {
    '&:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
    },
    '&.Mui-focused:after': {
      borderBottom: '2px solid rgba(0, 0, 0, 0.87)',
    },
    '& .MuiInputBase-input': {
      // Target the input element
      fontSize: '13px', // Set the desired font size here
    },
  };
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  return (
    <Grid
      container
      sx={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 2,
        mt: 2,
        overflowX: 'hidden',
        margin: 'auto',
      }}
    >
      <Grid item xs={12} md={8} textAlign="center">
        <Typography
          className="Nasaliza"
          variant="h6"
          sx={{
            fontWeight: 600,
            mb: 2, mt: 2,
            color: '#010066',
            textAlign: 'left',
          }}
        >
          Policy Holder Info
        </Typography>
        <Paper elevation={2} sx={{ padding: 2 }}>


          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} >
              <SelectField
                label="Select Policy Type"
                name="selectedPolicy"
                value={formData.selectedPolicy} // Use formData directly
                onChange={handlePolicyInfoChange}
                options={[
                  { value: 'HO1', label: 'HO1' },
                  { value: 'HO2', label: 'HO2' },
                  { value: 'HO3', label: 'HO3' },
                ]}
                error={!!errors.selectedPolicy}
                helperText={errors.selectedPolicy ? <FormHelperText error>{errors.selectedPolicy}</FormHelperText> : ""}
              />
            </Grid>
            {/* SSN Number */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="SSN Number"
                name="policy_holder_ssn"
                value={formData.policy_holder_ssn}
                onChange={handlePolicyInfoChange}
                type={showPassword ? 'text' : 'password'}
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
                sx={CustomStylesForTextFileds}
                error={!!errors.policy_holder_ssn}
                helperText={errors.policy_holder_ssn}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="FirstName"
                name="policy_holder_FirstName"
                value={formData.policy_holder_FirstName} // Use formData directly
                onChange={handlePolicyInfoChange}
                required
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
                sx={CustomStylesForTextFileds}
                error={!!errors.policy_holder_FirstName}
                helperText={errors.policy_holder_FirstName}
              />
            </Grid>

            {/* Last Name */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="LastName"
                name="policy_holder_LastName"
                value={formData.policy_holder_LastName} // Use formData directly
                onChange={handlePolicyInfoChange}
                required
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
                sx={CustomStylesForTextFileds}
                error={!!errors.policy_holder_LastName}
                helperText={errors.policy_holder_LastName}
              />
            </Grid>
            {/* Mobile Number */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Mobile Number"
                name="policy_holder_mobile"
                value={formData.policy_holder_mobile} // Use formData directly
                onChange={handlePolicyInfoChange}
                required
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
                sx={CustomStylesForTextFileds}
                error={!!errors.policy_holder_mobile}
                helperText={errors.policy_holder_mobile}
              />
            </Grid>

            {/* Email Address */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email Address"
                name="policy_holder_email"
                value={formData.policy_holder_email} // Use formData directly
                onChange={handlePolicyInfoChange}
                required
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
                sx={CustomStylesForTextFileds}
                error={!!errors.policy_holder_email}
                helperText={errors.policy_holder_email}
              />
            </Grid>

            {/* Occupation */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Occupation"
                name="policy_holder_occupation"
                value={formData.policy_holder_occupation} // Use formData directly
                onChange={handlePolicyInfoChange}
                required
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
                sx={CustomStylesForTextFileds}
                error={!!errors.policy_holder_occupation}
                helperText={errors.policy_holder_occupation}
              />
            </Grid>
          </Grid>
        </Paper>

        <Grid item xs={12} md={12} textAlign="center">
          <Grid item xs={12} md={12}  >
            <Typography
              className='Nasaliza'
              variant="h6"
              sx={{
                fontWeight: 600,
                mb: 2, mt: 2,
                color: '#010066',
                textAlign: 'left',
              }}
            >
              Policy Holder Address
            </Typography>

          </Grid>
          <Paper elevation={2} sx={{ padding: 2 }}>
            <Grid container spacing={2}>
              {/* Section for the Address Confirmation */}
              <Grid item xs={12} sm={9} sx={{ mb: 2 }}>
                {showAddress && suggestedAddress && (
                  <Typography variant="body2">
                    Below is the Validated Address, Click to use it &nbsp;&nbsp;
                    <Button
                      size="small"
                      sx={{
                        color: '#0B70FF',
                        fontWeight: 'bold',
                        border: '1px solid #0B70FF',
                        padding: '2px 8px', // Smaller padding for a sleek look
                        fontSize: '0.75rem', // Smaller font size
                        borderRadius: '4px', // Rounded edges
                        minWidth: 'auto', // Remove minimum width to make it smaller
                        textTransform: 'none', // Disable uppercase text transformation
                      }}
                      onClick={() => handleConfirmAddress(spittedAddress)}
                    >
                      Yes
                    </Button>
                  </Typography>
                )}
                {showAddress && suggestedAddress && (
                  <Typography
                    style={{ color: '#0B70FF', fontSize: '0.85rem', marginTop: "0.5rem" }}
                  >
                    "{suggestedAddress}"
                  </Typography>
                )}
              </Grid>

              {/* Section for the Validate Button and Edit Icon */}
              <Grid item xs={12} sm={3}>
                {!confirmAddress ? (
                  <StyledButtonComponent
                    buttonWidth={100}
                    onClick={handleValidateAddress}
                    disabled={
                      !isAddressValid ||
                      isValidatingAddress ||
                      confirmAddress
                    }
                    disableColor={"#B6E3FF"}
                    size="small"
                  >
                    {isValidatingAddress ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Validate"
                    )}
                  </StyledButtonComponent>
                ) : (
                  <CheckCircleOutlineIcon color="success" />
                )}
                <Tooltip title="Edit" arrow placement="right">

                  <IconButton
                    onClick={handleEditFields}
                    disabled={!confirmAddress}
                    sx={{ color: '#010066' }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid container spacing={2} >

              {/* Street Number */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Street Number"
                  name="policy_holder_street_number"
                  value={formData.policy_holder_street_number} // Use formData directly
                  onChange={handlePolicyInfoChange}
                  required
                  fullWidth
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  sx={CustomStylesForTextFileds}
                  error={!!errors.policy_holder_street_number}
                  helperText={errors.policy_holder_street_number}
                  disabled={confirmAddress && !editAddress}
                />
              </Grid>

              {/* Street Name */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Street Name"
                  name="policy_holder_street_name"
                  value={formData.policy_holder_street_name} // Use formData directly
                  onChange={handlePolicyInfoChange}
                  required
                  fullWidth
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  sx={CustomStylesForTextFileds}
                  error={!!errors.policy_holder_street_name}
                  helperText={errors.policy_holder_street_name}
                  disabled={confirmAddress && !editAddress}
                />
              </Grid>

              {/* City */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="City"
                  name="policy_holder_city"
                  value={formData.policy_holder_city} // Use formData directly
                  onChange={handlePolicyInfoChange}
                  required
                  fullWidth
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  sx={CustomStylesForTextFileds}
                  error={!!errors.policy_holder_city}
                  helperText={errors.policy_holder_city}
                  disabled={confirmAddress && !editAddress}
                />
              </Grid>

              {/* State */}
              <Grid item xs={12} sm={6}>
                <SelectField
                  label="State"
                  name="policy_holder_state"
                  value={formData.policy_holder_state} // Use formData directly
                  onChange={handlePolicyInfoChange}
                  options={states.map((state) => ({
                    value: state.value,
                    label: `${state.select} (${state.value})`,
                  }))}
                  required
                  disabled={confirmAddress && !editAddress}
                  error={!!errors.policy_holder_state}
                  helperText={
                    errors.policy_holder_state ? (
                      <FormHelperText error>
                        {errors.policy_holder_state}
                      </FormHelperText>
                    ) : (
                      ''
                    )
                  }
                />
              </Grid>

              {/* Country */}
              <Grid item xs={12} sm={6}>
                <SelectField
                  label="Country"
                  name="policy_holder_country"
                  value={formData.policy_holder_country} // Use formData directly
                  onChange={handlePolicyInfoChange}
                  options={[{ value: 'USA', label: 'USA' }]}
                  required
                  error={!!errors.policy_holder_country}
                  disabled={confirmAddress && !editAddress}
                  helperText={
                    errors.policy_holder_country ? (
                      <FormHelperText error>
                        {errors.policy_holder_country}
                      </FormHelperText>
                    ) : (
                      ''
                    )
                  }
                />
              </Grid>

              {/* ZIP */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="ZIP"
                  name="policy_holder_zip"
                  value={formData.policy_holder_zip} // Use formData directly
                  onChange={handlePolicyInfoChange}
                  required
                  fullWidth
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  sx={CustomStylesForTextFileds}
                  error={!!errors.policy_holder_zip}
                  helperText={errors.policy_holder_zip}
                  disabled={confirmAddress && !editAddress}
                />
              </Grid>


            </Grid>
          </Paper>
        </Grid>
        <Box sx={{ textAlign: 'right', mt: 2 }}>
          <StyledButtonComponent
            buttonWidth={100}
            variant="outlined"
            sx={{ mr: 2 }}
            disableColor={"#B6E3FF"}
            disabled={!enableReviewButton || isStepAddressValid }  // Correctly use the prop here 
            onClick={onReviewCheckValidation} // Add the onClick handler   
          >
            Review
          </StyledButtonComponent>
          <StyledButtonComponent
            buttonWidth={100}
            variant="outlined"
            onClick={handleNext}
            endIcon={<NavigateNextIcon />}
            disableColor={"#B6E3FF"}
            disabled={!isFormValid() || !confirmAddress}
          >
            Next
          </StyledButtonComponent>
        </Box>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={10000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>


    </Grid>
  );
};

export default PolicyInfo;